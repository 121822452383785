import IconArrowBottom from '../static/icons/arrow-bottom.svg';

const ToggleBlock = (props) => {
  const { faq, isActive = false, onToggle } = props;

  return (
    <div
      className={`FAQ-List ${isActive ? 'FAQ-List-Opened' : ''}`}
      onClick={onToggle}
    >
      <div className="FAQ-List-Question">
        {faq.question}
        <div className="FAQ-List-ExpandIcon">
          <img alt="" height={7} src={IconArrowBottom} width={10} />
        </div>
      </div>

      <div className="FAQ-List-Answer">
        <div
          className="FAQ-List-Answer-Content"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: faq.answer }}
        />
      </div>
    </div>
  );
};

export default ToggleBlock;
