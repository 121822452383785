import {
  Clusterer,
  FullscreenControl,
  GeolocationControl,
  Map,
  Placemark,
  TypeSelector,
  YMaps,
  ZoomControl,
} from '@pbe/react-yandex-maps';
import { useCallback, useEffect, useMemo, useState } from 'react';

import SelectField from '../components/selectField';
import Switcher from '../components/switcher';
import TablePointsList from '../components/tablePointsList';
import TablePointsListMobile from '../components/tablePointsListMobile';
import { useResize } from '../hooks/useResize';
import { getListByType } from '../models/ServiceBranches';
import mapIcon from '../static/icons/mapIcon.svg';
import notFoundIcon from '../static/icons/pointsNotFound.svg';
import '../styles/blocks/locationBlock.scss';

const DEFAULT_COORDINATES = [55.75381, 37.620934];

const VARIANT_RECEIVE = 'receive';
const VARIANT_SEND = 'send';

const VIEW_MAP = 'map';
const VIEW_LIST = 'list';

const LocationBlock = (props) => {
  const { filter, locale } = props;

  // Выбор варианта (получение или отправка).
  const variantsOptions = useMemo(() => {
    return [
      {
        option: VARIANT_SEND,
        title: filter.send,
      },
      {
        option: VARIANT_RECEIVE,
        title: filter.receive,
      },
    ];
  }, [filter.receive, filter.send]);

  const [selectedVariant, setSelectedVariant] = useState(VARIANT_RECEIVE);
  const handleSelectVariant = useCallback((nextVariant) => {
    setSelectedVariant(nextVariant);
  }, []);

  const [serviceBranches, setServiceBranches] = useState(null);

  useEffect(() => {
    const load = async () => {
      const data = await getListByType({ lang: locale, type: selectedVariant });

      setServiceBranches(data);
    };

    load();
  }, [locale, selectedVariant]);

  // Выбор страны.
  const countriesOptions = useMemo(() => {
    return (
      serviceBranches?.countries?.map((country) => ({
        label: country.label,
        value: country.code,
      })) ?? []
    );
  }, [serviceBranches]);

  const [selectedCountry, setSelectedCountry] = useState(null);
  const handleSelectCountry = useCallback((nextCountry) => {
    setSelectedCountry(nextCountry);
  }, []);

  const countrySubtree = useMemo(() => {
    return serviceBranches?.countries?.find(
      (country) => selectedCountry === country.code,
    );
  }, [selectedCountry, serviceBranches?.countries]);

  useEffect(() => {
    setSelectedCountry(countriesOptions[0]?.value ?? null);
  }, [countriesOptions]);

  // Выбор города.
  const citiesOptions = useMemo(() => {
    return (
      countrySubtree?.cities?.map((city) => ({
        label: city.label,
        value: city.code,
      })) ?? []
    );
  }, [countrySubtree?.cities]);

  const [selectedCity, setSelectedCity] = useState(null);
  const handleSelectCity = useCallback((nextCity) => {
    setSelectedCity(nextCity);
  }, []);

  const citySubtree = useMemo(() => {
    return countrySubtree?.cities?.find((city) => selectedCity === city.code);
  }, [countrySubtree?.cities, selectedCity]);

  useEffect(() => {
    setSelectedCity(citiesOptions[0]?.value ?? null);
  }, [citiesOptions]);

  // Точки на карте.
  const points = useMemo(() => {
    return citySubtree?.branches ?? [];
  }, [citySubtree?.branches]);

  // Координаты центра карты.
  const center = useMemo(() => {
    return (
      citySubtree?.coordinates ??
      countrySubtree?.coordinates ??
      DEFAULT_COORDINATES
    );
  }, [citySubtree?.coordinates, countrySubtree?.coordinates]);

  // Выбор отображения (карта или список).
  const viewsOptions = useMemo(() => {
    return [
      {
        option: VIEW_MAP,
        title: filter.onmap,
      },
      {
        option: VIEW_LIST,
        title: filter.onlist,
      },
    ];
  }, [filter.onlist, filter.onmap]);

  const [selectedView, setSelectedView] = useState(VIEW_MAP);
  const handleSelectView = useCallback((nextView) => {
    setSelectedView(nextView);
  }, []);

  const currentWidth = useResize();

  const renderedView = useMemo(() => {
    if (selectedView === VIEW_MAP) {
      return (
        <div className="Locations-Container-Results-MapBlock">
          <YMaps key={locale} query={{ lang: locale, load: 'package.full' }}>
            <Map
              state={{
                center,
                zoom: 9,
                controls: [],
              }}
              width="100%"
              height="540px"
              borderradius="12px"
              overflow="hidden"
            >
              <ZoomControl options={{ float: 'right' }} />
              <FullscreenControl />
              <GeolocationControl options={{ float: 'left' }} />
              <TypeSelector options={{ float: 'right' }} />

              <Clusterer
                options={{
                  preset: 'islands#invertedGreenClusterIcons',
                  groupByCoordinates: false,
                }}
              >
                {points.map((point) => (
                  <Placemark
                    key={`${point.label}-${point.address}`}
                    geometry={point.coordinates}
                    options={{
                      iconLayout: 'default#image',
                      iconImageSize: [64, 64],
                      iconImageHref: mapIcon,
                    }}
                    properties={{
                      hintContent: point.label,
                      balloonContentHeader: point.label,
                      balloonContent: `${point.address} <br>${filter.phone}: ${point.phone} <br> <b>${filter.worktime}: </b><br>${point.workSchedule}`,
                      balloonContentFooter: `${
                        filter[selectedVariant]
                      }: ${point.currencies.join(', ')}`,
                    }}
                  />
                ))}
              </Clusterer>
            </Map>
          </YMaps>
        </div>
      );
    }

    if (points.length === 0) {
      return (
        <div className="Locations-Container-Results-ListBlock">
          <div className="Locations-Container-Results-ListBlock-NotFound">
            <div className="Locations-Container-Results-ListBlock-NotFound-Icon">
              <img src={notFoundIcon} />
            </div>
            {filter.notFound}
          </div>
        </div>
      );
    }

    if (currentWidth >= 744) {
      return (
        <div className="Locations-Container-Results-ListBlock">
          <TablePointsList
            aliasVariant={selectedVariant}
            branches={points}
            filter={filter}
          />
        </div>
      );
    }

    return (
      <TablePointsListMobile
        aliasVariant={selectedVariant}
        branches={points}
        filter={filter}
      />
    );
  }, [
    center,
    currentWidth,
    filter,
    locale,
    points,
    selectedVariant,
    selectedView,
  ]);

  return (
    <div className="Block">
      <div className="Block-Container Block-Container-Centered Locations">
        <div className="Locations-Container">
          <div className="Locations-Container-Filter">
            <div className="Locations-Container-Filter-Option Locations-Container-Filter-Option-Switcher">
              <div className="Locations-Switcher">
                <Switcher
                  items={variantsOptions}
                  currentAlias={selectedVariant}
                  handleFunction={handleSelectVariant}
                  className="Locations-Switcher-Block"
                  additionalClassname="Switcher-Block-Fullwidth"
                />
              </div>
            </div>

            <div className="Locations-Container-Filter-Option">
              <div className="Locations-Container-Filter-Option-Label">
                {filter.country}
              </div>

              <SelectField
                onChange={handleSelectCountry}
                options={countriesOptions}
                value={selectedCountry}
                withIcon
              />
            </div>

            <div className="Locations-Container-Filter-Option">
              <div className="Locations-Container-Filter-Option-Label">
                {filter.city}
              </div>

              <SelectField
                onChange={handleSelectCity}
                options={citiesOptions}
                value={selectedCity}
              />
            </div>
          </div>

          <div className="Locations-Container-Divider"></div>

          <div className="Locations-Container-Results">
            <div className="Locations-Container-Filter-Option Locations-Container-Filter-Option-Switcher">
              <div className="Locations-Switcher">
                <Switcher
                  items={viewsOptions}
                  currentAlias={selectedView}
                  handleFunction={handleSelectView}
                  className="Locations-Switcher-Block"
                  additionalClassname="Switcher-Block-Fullwidth"
                />
              </div>
            </div>

            <div className="Locations-Container-Results">{renderedView}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LocationBlock;
